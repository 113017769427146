.new-login-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
}

.new-login-error-content {
  width: 688px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-login-error--text-content {
  display: flex;
  flex-direction: column;
  margin-top: 128px;
  font-size: 20px;
  font-weight: 400;
}

.new-login-error--text-content--second-text {
  margin: 0;
}

.new-login-error--text-content--second-text a {
  text-decoration: none;
  font-weight: 600;
  color: #000;
}

.new-login-error--button {
  border: none;
  background-color: #0183C7;
  color: #fff;
  width: 279px;
  height: 48px;
  font-weight: 400;
  font-size: 20px;
  margin-top: 86px;
}

@media screen and (max-width: 1023px) {
  .new-login-error-content {
    width: 600px;
  }
  .new-login-error--text-content {
    margin-top: 100px;
  }
  .new-login-error--button {
    margin-top: 80px;
  }
}

@media screen and (max-width: 768px) {
  .new-login-error-container {
    justify-content: flex-start;
  }
  .new-login-error-content {
    width: 360px;
    margin-top: 140px;
  }
  .new-login-error--text-content {
    text-align: center;
  }
  .new-login-error--text-content--second-text {
    margin-top: 8px;
  }
  .den-logo {
    transform: scale(0.6);
  }
}
