$gray-25: #fafafa;
$gray-38: #faf9f7;
$gray-50: #f2f2f2;
$gray-75: #f5f5f5;
$gray-100: #f7f7f7;
$gray-200: #ebebeb;
$gray-210: #e6e6e6;
$gray-240: #d8d8d8;
$gray-270: #c2c2c2;
$gray-285: #cccccc;
$gray-300: #b3b3b3;
$gray-400: #999999;
$gray-450: #808080;
$gray-500: #737373;
$gray-600: #666666;
$gray-700: #4d4d4d;
$gray-800: #335272;
$gray-900: #222;

$white: #fff;
$offwhite: #f4f5f1;
$black: #000;
$orange: #f49e2e;
$lightblue: #e5f4ff;
$blue:   #1584c4;
$dark-blue: #0c7de9;
$teal: #4fb1e5;
$dark-teal: #349ed7;
$red:    #f42e44;
$green: #349d42;
$yellow: #f0ad4e;
$dark: $gray-900;

$table-blue: #2ef4e826;
$lightochre: #f4bd2e26;

$bootstrap-blue: #337ab7;

$status-awesome: $green;
$status-good: $teal;
$status-ok: $gray-500;
$status-bad: $orange;
$status-disaster: $red;

$brand-pink: #f03964;