@font-face {
  font-family: 'FoundersGrotesk';
  src: url("../fonts/FoundersGroteskWeb-Regular.eot") format("eot"),
       url("../fonts/FoundersGroteskWeb-Regular.woff") format("woff"),
       url("../fonts/FoundersGroteskWeb-Regular.woff2") format("woff2")
}

@font-face {
  font-family: 'FoundersGrotesk';
  font-weight: 300;
  src: url("../fonts/FoundersGroteskWeb-Light.eot") format("eot"),
       url("../fonts/FoundersGroteskWeb-Light.woff") format("woff"),
       url("../fonts/FoundersGroteskWeb-Light.woff2") format("woff2")
}

@font-face {
  font-family: 'FoundersGrotesk';
  font-weight: 500;
  src: url("../fonts/FoundersGroteskWeb-Medium.eot") format("eot"),
       url("../fonts/FoundersGroteskWeb-Medium.woff") format("woff"),
       url("../fonts/FoundersGroteskWeb-Medium.woff2") format("woff2")
}

@font-face {
  font-family: 'FoundersGrotesk';
  font-weight: bold;
  src: url("../fonts/FoundersGroteskWeb-Semibold.eot") format("eot"),
       url("../fonts/FoundersGroteskWeb-Semibold.woff") format("woff"),
       url("../fonts/FoundersGroteskWeb-Semibold.woff2") format("woff2")
}

@font-face {
  font-family: "pitch";
  font-weight: 400;
  src: url("https://assets.initialized.com/Pitch-Regular.otf") format("otf"),
       url("https://assets.initialized.com/pitch-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Söhne";
  font-weight: 300;
  src: url("https://assets.initialized.com/So%CC%88hne-Leicht.otf") format("otf"),
       url("https://assets.initialized.com/soehne-leicht.woff2") format("woff2");
}

@font-face {
  font-family: "Söhne";
  font-weight: 400;
  src: url("https://assets.initialized.com/So%CC%88hne-Buch.otf") format("otf"),
       url("https://assets.initialized.com/soehne-buch.woff2") format("woff2");
}

@font-face {
  font-family: "Söhne Mono";
  font-weight: 400;
  text-transform: uppercase;
  src: url("https://assets.initialized.com/So%CC%88hneMono-Buch.otf") format("otf"),
       url("https://assets.initialized.com/soehne-mono-buch.woff2") format("woff2");
}
