@import '../../scss/colors.scss';

:disabled {
  opacity: 0.5;
}

.folio-button {
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
  border: none;

  &.default {
    color: $gray-900;
    background-color: $white;
    border: 1px solid $gray-285;
    border-radius: 2px;
  }

  &.primary {
    color: $white;
    background-color: $blue;
  }

  &.grey {
    color: $white;
    background-color: $gray-600;
  }

  &.secondary {
    color: $blue;
    background-color: transparent;
  }

  &.warning {
    color: $white;
    background-color: $yellow;
  }

  &.danger {
    color: $white;
    background-color: $red;
  }

  &.tiny {
    padding: 0 4px 0 4px;
    height: 22px;
    font-size: 12px;
    width: auto;
  }

  &.small {
    font-size: 14px;
    min-width: 100px;
    min-height: 25px;
    margin-left: 5px;
    margin-right: 5px;

    &.larger-cta {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &.large {
    font-size: 18px;
    padding: 6px 50px 6px 50px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
