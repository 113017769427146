@import '../../scss/colors.scss';

.avatar {

  &.portrait {
    height: 80px;
    width: 80px;
  }

  &.large {
    height: 50px;
    width: 50px;
  }

  &.normal {
    height: 30px;
    width: 30px;
  }

  &.small {
    height: 20px;
    width: 20px;
  }

  &.tiny {
    height: 18px;
    width: 18px;
  }
  &.empty-avatar {
    background-color: transparent;
    border-radius: 50%;
    border-width: 1px;
    border-color: $gray-500;
    border-style: dashed;
  }
}
