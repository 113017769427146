.tag-container {
  display: inline-block;
  text-align: center;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1rem;
  &.tag-small {
    font-size: 12px;
  }
  &.tag-large {
    font-size: 16px;
  }
}

